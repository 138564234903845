.footer-container {
    background-color: #eeeeee;
    padding: 16px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .footer-logo {
    max-height: 80px;
    cursor: pointer; /* Asegura que el logo tenga un cursor de puntero */
    transition: filter 0.3s ease; /* Agrega una transición suave */
  }
  
  .footer-logo:hover {
    filter: brightness(1.2); /* Incrementa el brillo al hacer hover */
  }
  
  .footer-link {
    text-decoration: none;
    color: inherit;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  /* Media queries para vista móvil */
  @media (max-width: 959px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-logo {
      margin-bottom: 16px;
    }
  
    .footer-content > div {
      text-align: center;
      margin-bottom: 16px;
    }
  }
  