.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
    min-height: 72px;
  }
  
  .navbar-logo-container {
    display: flex;
    align-items: center;
    margin-left: 16px;
    cursor: pointer;
  }
  
  .navbar-logo {
    height: 100%; 
    max-height: 72px;
  }
  
  .navbar-title {
    display: none;
  }
  
  .navbar-menu-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }
  
  .navbar-button {
    margin: 0 10px; /* Margin between buttons */
    color: white;
    display: block;
    font-family: "Gabarito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-size: 16px;
  }
  
  .navbar-dropdown-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #37779b;
    width: 100%;
    position: absolute;
    top: 72px;
    left: 0;
    z-index: 999;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
  }
  
  .navbar-dropdown-menu.open {
    max-height: 500px;
  }
  
  .navbar-dropdown-item {
    color: white;
    width: 100%;
    padding: 16px;
    text-align: center;
    text-transform: none;
    border-top: none;
    font-family: "Gabarito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
  }
  
  /* Media queries to adjust the visibility of elements according to screen size */
  @media (min-width: 960px) {
    .navbar-logo-container {
      display: flex;
    }
    .navbar-title {
      display: none;
    }
    .navbar-menu-button {
      display: none;
    }
    .navbar-links {
      display: flex;
    }
    .navbar-dropdown-menu {
      display: none !important;
    }
  }
  
  @media (max-width: 959px) {
    .navbar-logo-container {
      display: flex; /* Mostrar logo en pantallas pequeñas */
      align-items: center;
    }
    .navbar-title {
      display: none;
    }
    .navbar-menu-button {
      display: flex;
      margin-left: auto;
    }
    .navbar-links {
      display: none;
    }
  }
  