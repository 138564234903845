.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  text-align: center;
}

main {
  flex: 1;
}

.gabarito {
  font-family: "Gabarito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.gabarito-bold {
  font-family: "Gabarito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.contact-button {
  position: fixed;
  bottom: 32px;
  right: 32px;
  z-index: 1000;
}
