.full-width-section {
  width: 100%;
  padding: 50px 20px;
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Gabarito', sans-serif; /* Asegura que la fuente Gabarito se aplique */
  min-height: 500px; /* Ajusta la altura mínima según sea necesario */
}

.content-container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente el contenido */
  align-items: center; /* Centra horizontalmente el contenido */
}

.full-width-section h2 {
  font-size: 3.5rem; /* Ajusta el tamaño del texto del título */
  margin-bottom: 20px; /* Ajusta el margen inferior */
}

.full-width-section p {
  font-size: 2rem; /* Ajusta el tamaño del texto del párrafo */
  margin-bottom: 20px; /* Ajusta el margen inferior */
}