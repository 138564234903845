.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
  }
  
  .hero-section {
    width: 100%; /* Asegura que la sección de héroe ocupe todo el ancho */
    overflow: hidden;
  }
  
  .hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Gabarito';
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-family: 'Gabarito';
  }
  
  .hero-button {
    border-color: #37779b;
    color: #37779b;
  }
  
  /* Media queries para dispositivos móviles */
  @media (max-width: 959px) {
    .hero-title {
      font-size: 1.5rem; /* Ajusta el tamaño del título en dispositivos móviles */
    }
  
    .hero-subtitle {
      font-size: 1rem; /* Ajusta el tamaño del subtítulo en dispositivos móviles */
    }
  }
  