/* src/components/HeroCarousel.css */
.carousel .slide {
  background: none;
  max-height: 400px; /* Limita la altura máxima del carrusel */
  overflow: hidden; /* Asegura que cualquier contenido adicional no se muestre */
}

.carousel .slide img {
  width: 100%;
  height: auto;
  max-height: 400px; /* Asegura que las imágenes no excedan la altura máxima */
  object-fit: cover; /* Ajusta la imagen para que cubra el área sin distorsionarse */
}

.carousel .legend {
  position: absolute;
  bottom: 20px;
  left: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
}

.carousel .legend h1 {
  font-size: 2.5rem;
  font-family: 'Gabarito', sans-serif;
}

.carousel .legend p {
  font-size: 1.25rem;
  font-family: 'Gabarito', sans-serif;
}

/* Personalización de los indicadores */
.carousel .control-dots .dot {
  background-color: transparent;
  border: 2px solid #fff; /* Cambia el color del borde */
  width: 24px; /* Ajusta el ancho de los indicadores */
  height: 4px; /* Ajusta la altura de los indicadores */
  margin: 0 8px; /* Ajusta el margen para separarlos más */
  opacity: 0.5;
}

.carousel .control-dots .dot.selected {
  background-color: #fff; /* Color de fondo del indicador seleccionado */
  opacity: 1;
}

/* Media queries para dispositivos móviles */
@media (max-width: 959px) {
  .carousel .slide {
    max-height: 200px; /* Ajusta la altura máxima del carrusel en dispositivos móviles */
  }

  .carousel .slide img {
    max-height: 200px; /* Ajusta la altura máxima de las imágenes en dispositivos móviles */
  }

  .carousel .legend {
    bottom: 10px;
    left: 10px;
    right: 10px;
    transform: translateX(-10%); /* Ajusta la posición en dispositivos móviles */
    max-width: calc(100% - 20px); /* Asegura que la leyenda no se salga de la pantalla */
    padding: 10px; /* Ajusta el padding en dispositivos móviles */
  }

  .carousel .legend h1 {
    font-size: 1.5rem; /* Ajusta el tamaño del texto del título en dispositivos móviles */
  }

  .carousel .legend p {
    font-size: 1rem; /* Ajusta el tamaño del texto del párrafo en dispositivos móviles */
  }
}

/* Ajustes adicionales para dispositivos no móviles */
@media (min-width: 960px) {
  .carousel .legend {
    max-width: 40%; /* Ajusta la máxima anchura de la leyenda en dispositivos más grandes */
    padding: 20px;
    left: 50%; /* Mantén la leyenda centrada horizontalmente */
    transform: translateX(-20%); /* Asegura que la leyenda esté centrada */
  }
}
