.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f5f5f5;
  }
  
  .not-found-title {
    font-size: 6rem;
    font-family: 'Gabarito', sans-serif;
    color: #303030;
  }
  
  .not-found-description {
    font-size: 1.5rem;
    font-family: 'Gabarito', sans-serif;
    color: #303030;
    margin-bottom: 20px;
  }
  
  .not-found-link {
    font-size: 1.25rem;
    font-family: 'Gabarito', sans-serif;
    color: #37779b;
    text-decoration: none;
    border: 2px solid #37779b;
    padding: 10px 20px;
    border-radius: 50px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .not-found-link:hover {
    background-color: #37779b;
    color: #ffffff;
  }
  